import { combineReducers } from "redux";
import routeReducer from "./routeReducer";
import vehiclesReducer from "./vehicleReducer";
import metricsReducer from "./metricsReducer";
import popupReducer from "./popupReducer";
import vehicleSelectionReducer from "./vehicleSelectionReducer";
import vehicleListReducer from "./vehicleListReducer";
import settingsReducer from "./settingsReducer";
import relayReducer from "./relayReducer";
import timestampWeatherReducer from "./timestampWeatherReducer";

const rootReducer = combineReducers({
  vehicleRoutes: routeReducer,
  vehicle: vehiclesReducer,
  metrics: metricsReducer,
  popupView: popupReducer,
  vehicleSelection: vehicleSelectionReducer,
  vehicleList: vehicleListReducer,
  settings: settingsReducer,
  relay: relayReducer,
  timestampWeather: timestampWeatherReducer,
});

export default rootReducer;

//export type State = ReturnType<typeof rootReducer>;
